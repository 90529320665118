import { policyList } from "@/enums/permissions";
import { PriceGroupInterface } from "@/interfaces/priceGroups";
import { store } from "@/internal";
import { PriceGroupsTableMetadata } from "@/metadata/priceGroups";
import { priceGroupService } from "@/services/priceGroup.service";
import { priceGroupsActionService } from "@/services/priceGroups.action.service";
import { PageNavAction } from "@/types/types";
import { BooleanCheck } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PriceGroupFiltersComponent } from "./PriceGroupFilters/PriceGroupFilters.component";
import Template from "./PriceGroups.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    PriceGroupFiltersComponent
  }
})
export default class PriceGroupComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public headers: TableHeader[] = PriceGroupsTableMetadata;
  public generalActions: ActionSubheader[] = priceGroupsActionService.getGeneralActions(
    this.filtersCallback
  );
  public enableMultiActions = true;

  /**
   * Flag to determine if a filter was applied on last search.
   */
  public activeFilter: boolean = true;

  /**
   * Enable filter view for qualified/non qualified Price Group.
   */
  public showFilters: boolean = false;

  /**
   * Loading state.
   */
  public loading: boolean = true;
  /**
   * term to search with for Price Group
   */
  public searchTerm: string = "";
  /**
   * Selected column to sort list
   */
  public columnToSortBy: string = "id";
  public sortDescending: boolean = true;

  public priceGroups: PriceGroupInterface[] = [];

  /**
   * Row actions data for the table.
   */
  public rowActions: TableAction[] = priceGroupsActionService.getPriceGroupRowActions(
    this.getPriceGroupList
  );

  /**
   * Pagination data.
   */
  public pagination: TablePagination = priceGroupService.getResetedPagination();

  /**
   * Filter by which Price Group will be fetched
   */
  public filterToPass: string = "ACTIVE";

  /**
   * Multi actions options.
   */
  protected multiActions: TableAction[] = priceGroupsActionService.getMultiActions(
    this.getPriceGroupList
  );

  public setFilterStatus(newValue: boolean) {
    this.activeFilter = newValue;
  }

  /**
   * Opens filters qualified/non qualified filters.
   */
  public filtersCallback() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Triggers search for qualified/non qualified Price Group.
   * @param header: TableHader
   */
  public onFilter(filter: { active: boolean }) {
    this.filterToPass = filter.active ? "ACTIVE" : "INACTIVE";
    this.pagination = priceGroupService.getResetedPagination();
    this.getPriceGroupList();
  }
  /**
   * Updates requests on column name click to sort.
   * @param header: TableHader
   */
  public onSort(header: TableHeader) {
    this.columnToSortBy = header.value;
    this.sortDescending = !!header.descending;
    this.getPriceGroupList();
  }

  /**
   * Updates requests on table page change event.
   * @param pagination: TablePagination
   */
  public onPageChange(pagination: TablePagination) {
    this.pagination = pagination;
    this.getPriceGroupList();
  }

  /**
   * Updates table data based on search component.
   * @param term: string
   */
  public async onSearch(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.pagination = priceGroupService.getResetedPagination();
    this.getPriceGroupList();
  }
  public async onCloseSearchPanel() {
    this.searchTerm = "";
    this.pagination = priceGroupService.getResetedPagination();
    this.getPriceGroupList();
  }

  public async getPriceGroupList() {
    this.loading = true;
    this.priceGroups = await priceGroupService.getPriceGroupTableList(
      this.pagination.currentPage,
      this.pagination.itemsPerPage,
      this.filterToPass,
      this.searchTerm,
      this.columnToSortBy,
      this.sortDescending
    );
    this.pagination = await priceGroupService.getPagination();
    this.loading = false;
  }

  protected async mounted() {
    this.getPriceGroupList();
    if (!this.hasPermission(policyList.modifyPriceGroup)) {
      this.rowActions[0] = {
        icon: "fal fa-eye",
        visibleCondition: () =>
          !this.hasPermission(policyList.modifyPriceGroup),
        action: doc => {
          store.dispatch("RouterModule/go", {
            name: "price-groups-edit",
            params: {
              id: doc.uid
            }
          });
        }
      };
    }
    this.setPageNav({
      title: "price_groups.title",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: this.onSearch,
        onClose: this.onCloseSearchPanel
      }
    });
    this.enableMultiActions = this.hasPermission(
      policyList.activateDeactivatePriceGroup
    );
  }
}
