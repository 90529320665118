import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { priceGroupService } from "@/services/priceGroup.service";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";

class PriceGroupsActionService {
  public getGeneralActions(filtersCallback: () => void): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createPriceGroup
          ),
        action: () => {
          store.dispatch("RouterModule/go", {
            name: "price-groups-add"
          });
        }
      },
      {
        icon: "fal fa-filter",
        id: "btn_filter_prod",
        action: filtersCallback
      },
      {
        icon: "fal fa-search",
        id: "btn_search_prod",
        otherComponent: {
          name: "TableSearchComponent"
        }
      }
    ];
  }

  public getPriceGroupRowActions(onSuccess: () => void): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        id: "btn_edit_prod",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyPriceGroup
          ),
        action: arg => {
          store.dispatch("RouterModule/go", {
            name: "price-groups-edit",
            params: {
              id: arg.uid
            }
          });
        }
      },
      {
        icon: "",
        switch: "is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.activateDeactivatePriceGroup
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n
            .t("price_groups_status_change_message")
            .toString(),
          modalSuccessText: "Yes",
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            try {
              await priceGroupService.togglePriceState(
                arg!.modelValue ? "enable" : "disable",
                arg!.item!.uid
              );
              arg.unselectModal();
              onSuccess();
            } catch (e) {
              EventBus.$emit("unSelectedModalActive");
              arg.unselectModal();
            }
          },
          modalCancelText: "No"
        }
      }
    ];
  }

  public getMultiActions(onSuccess: () => void): TableAction[] {
    return [
      {
        icon: "fa-toggle-off",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.activateDeactivatePriceGroup
          ),
        vuetifyProps: () => ({
          disabled: !store.getters["PermissionsModule/hasPermission"](
            policyList.modifyPriceGroup
          )
        }),
        async action(priceGroups: []) {
          const payload = priceGroups.map((i: { id: number } | null) => i!.id);
          await priceGroupService.togglePriceGroupsStateMultiple(
            "disable",
            payload
          );
          onSuccess();
        }
      },
      {
        icon: "fa-toggle-on",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.activateDeactivatePriceGroup
          ),
        vuetifyProps: () => ({
          disabled: !store.getters["PermissionsModule/hasPermission"](
            policyList.modifyPriceGroup
          )
        }),
        async action(priceGroups: []) {
          const payload = priceGroups.map((i: { id: number } | null) => i!.id);
          await priceGroupService.togglePriceGroupsStateMultiple(
            "enable",
            payload
          );
          onSuccess();
        }
      }
    ];
  }
}

export const priceGroupsActionService = new PriceGroupsActionService();
