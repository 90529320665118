import { HttpQuery } from "@/interfaces/httpQuery";
import { PriceGroupPayload, PriceGroups } from "@/interfaces/priceGroups";
import { PricingRule } from "@/interfaces/product";
import { i18n } from "@/plugins/i18n";
import { AxiosResponse } from "axios";
import Vue from "vue";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";
import { pricePointService } from "./pricePoint.service";

class PriceGroupService extends HttpService {
  protected uri: string = "price/pricing_group";
  public async getPriceGroupTableList(
    page = 1,
    perPage = 10,
    status = "ACTIVE",
    searchTerm = "",
    sortBy = "id",
    descending = true
  ) {
    try {
      const query: HttpQuery = {
        page,
        per_page: perPage,
        sort: `${descending ? "-" : ""}${sortBy}`,
        "q[name_contains]": searchTerm,
        "q[group_type_eq]": "PRICING_GROUP"
      };
      if (status === "ACTIVE") {
        query[`q[is_active]`] = 1;
      } else {
        query[`q[is_active_is_false]`] = 1;
      }
      const response = await super.get(query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getPriceGroupList() {
    try {
      const query: HttpQuery = {
        no_pagination: true,
        "q[is_active]": 1,
        "q[group_type_eq]": "PRICING_GROUP"
      };
      const response = await super.get(query);
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getPriceGroupById(
    priceGroupId: string,
    locationId?: string | null
  ) {
    try {
      let query: HttpQuery = {};
      if (locationId !== undefined || locationId !== null) {
        query = {
          location_id: locationId
        };
      }
      const response = await super.findById(priceGroupId, query);
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  // changing payload from PriceGroups to PriceGroupPayload just to set type of payload in updatePriceRuleSet()
  public async saveNewPriceGroup(payload: PriceGroupPayload) {
    let value = "";
    try {
      const response = await super.post(payload.price_group);
      if (response) {
        value = response.uid;
      }
      if (payload.price_points.length) {
        this.updatePriceRuleSet(value, payload, response.id);
      }
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async updatePriceGroup(payload: PriceGroups, id: string) {
    try {
      const response = await super.put({ id }, payload);
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
  // sending new payload bcz we need to save the price groups at all locations, previously is was only for current location
  public async updatePriceRuleSet(
    id: string,
    payload: PriceGroupPayload,
    data: number
  ) {
    const priceGroupCode = `PRICING_GROUP_${data}`;
    const url = `price/rule_set/${priceGroupCode}`;
    payload.price_points.map(
      item => (item.rules = pricePointService.formatPayloadRules(item.rules))
    );
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PUT",
        url,
        data: payload
      });
      this.updateProductPriceGroup(id);
      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async updateProductPriceGroup(id: string) {
    try {
      const url = `price/pricing_group/product/${id}`;
      const response: AxiosResponse = await Vue.axios({
        method: "PUT",
        url
      });
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async togglePriceState(action: string, id: number) {
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PATCH",
        url: `${this.uri}/${id}/${action}`
      });
      messagesService.renderSuccessMessage(
        i18n.t("price_groups_changed").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async togglePriceGroupsStateMultiple(
    action: string,
    payload: number[]
  ) {
    try {
      const response: AxiosResponse = await Vue.axios({
        method: "PATCH",
        url: `${this.uri}/${action}`,
        data: payload
      });
      messagesService.renderSuccessMessage(
        i18n.t("price_groups_changed").toString()
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }
}

export const priceGroupService: PriceGroupService = new PriceGroupService();
